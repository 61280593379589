export function handleWebhook(url, data) {
  const body = {
    email: data.email,
    name: data.full_name,
    marketing: data.marketing,
    news: data.news,
  };

  const headers = {
    // Authorization: `Bearer ${API_KEY}`,
    Accept: "application/json; charset=utf-8",
    "Content-Type": "application/json; charset=UTF-8",
  };

  fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => console.log(data))
    .catch((error) => console.error("Error:", error));
}

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Paragraph = tw.p`my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-base xl:text-lg text-center mx-auto block`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

//Our Professional <span tw="text-primary-500">Services</span>

export default ({
  heading = (
    <>
      Continue to <span tw="text-primary-500">improve and grow</span> with our
      innovative software.
    </>
  ),
  // heading = "Continue to <span>improve and grow</span> with our innovative software.",
  description = "Estatia has been developed by a team of world leading developers. Our philosophy is to continuously innovate and improve the software.",
  addBlobs = false,
  id = "features",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Secure",
      description:
        "Robust security measures, ensuring data protection, user privacy, and safeguarding against unauthorized access or cyber threats.",
    },
    {
      imageSrc: SupportIconImage,
      title: "Easy to use",
      description:
        "Intuitive interface and straightforward navigation, allowing users of all skill levels to accomplish tasks efficiently without a steep learning curve.",
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Scalable",
      description:
        "Handle increasing workloads and user demands, allowing it to grow seamlessly without compromising performance or stability.",
    },
    {
      imageSrc: ReliableIconImage,
      title: "Compatible",
      description:
        "Work seamlessly across various devices, operating systems, and third-party applications, ensuring smooth integration and interoperability.",
    },
    {
      imageSrc: FastIconImage,
      title: "Fast",
      description:
        "Optimized for speed and performance, delivering quick response times and minimal loading delays to enhance user experience.",
    },
    {
      imageSrc: SimpleIconImage,
      title: "SEO friendly",
      description:
        "Designed with search engine optimization best practices in mind, ensuring high visibility and ranking on search engine results pages.",
    },
  ];

  return (
    <Container id={id}>
      <ThreeColumnContainer>
        <Heading>{heading}</Heading>
        <Paragraph>{description}</Paragraph>

        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || ""}</span>
                <p className="description">{card.description || ""}</p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      {addBlobs && <DecoratorBlob />}
    </Container>
  );
};

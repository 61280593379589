import { React, useEffect } from "react";
import styled from "styled-components"; //eslint-disable-line
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts";

const PrimaryBackgroundContainer = tw.div`py-20 lg:py-24 bg-primary-500 rounded-lg relative`;
const Row = tw.div`px-8 max-w-screen-lg mx-auto flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`;
const TextContainer = tw(ColumnContainer)``;

const Header = tw.h2`
  flex justify-between items-center text-gray-100
  max-w-screen-xl mx-auto text-2xl font-bold sm:text-3xl lg:text-4xl mb-8
`;

const Text = tw.p`text-gray-100 text-xl`;

const LinksContainer = tw(
  ColumnContainer
)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`;

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`bg-[var(--button-primary)] text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200`;

const SecondaryLink = tw(
  Link
)`bg-[var(--button-secondary)] text-gray-100 hover:bg-[var(--button-secondary-hover)] hover:text-primary-500 hover:border-primary-500`;

// Global variable to track initialization state
let hasInitialized = false;

const handleScroll = (event, target) => {
  event.preventDefault();

  const scrollToElement = document.querySelector(target);
  if (scrollToElement) {
    scrollToElement.scrollIntoView({ behavior: "smooth" });
  }
};

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-primary-700 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-primary-700 opacity-50`;
export default ({
  id = "",
  header = "Header",
  text = "Developers all over the world are happily using Treact.",
  primaryLinkText = "Get Started",
  primaryLinkUrl = "http://timerse.com",
  secondaryLinkText = "Contact Us",
  secondaryLinkUrl = "http://google.com",
  pushDownFooter = true,
  withBlobs = false,
  scheduleId = "AcZssZ2BEDnmx9m0QbBAsbmPsz2O0TRezF29HS8p4LTc0YI5aok2qyieQrraw2kS3W7ECM9Rs9YcNFKj",
  calendarButtonColor = "#333",
  buttonLabel = "Book a Demo",
  googleSchedule = false,
}) => {
  useEffect(() => {
    const observer = new MutationObserver((mutations, obs) => {
      const dynamicButton = document.getElementById(
        "google-schedule-button"
      ).nextElementSibling;
      if (dynamicButton) {
        dynamicButton.style.padding = "20px 40px";
        dynamicButton.style.borderRadius = "9999px";
        dynamicButton.style.backgroundColor = "#2f4858";
        obs.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Cleanup function
    return () => observer.disconnect();
  }, []);

  if (!hasInitialized) {
    hasInitialized = true;

    const link = document.createElement("link");
    link.href =
      "https://calendar.google.com/calendar/scheduling-button-script.css";
    link.rel = "stylesheet";

    const script = document.createElement("script");
    script.src =
      "https://calendar.google.com/calendar/scheduling-button-script.js";
    script.rel = "prefetch";
    script.async = true;

    // Append the link and script elements to the body
    if (!document.body.contains(link)) {
      document.body.appendChild(link);
      document.body.appendChild(script);
    } else {
      return;
    }

    // Function to initialize the scheduling button
    const initializeSchedulingButton = () => {
      const target = document.querySelector("#google-schedule-button");
      if (target) {
        // eslint-disable-next-line no-undef
        calendar.schedulingButton.load({
          url: `https://calendar.google.com/calendar/appointments/schedules/${scheduleId}?gv=true`,
          color: calendarButtonColor,
          label: buttonLabel,
          target,
        });
        document.dispatchEvent(new CustomEvent("dynamicElementAdded"));
      } else {
        console.log("Target element for Google Schedule button not found.");
      }
    };

    document.addEventListener("dynamicElementAdded", () => {
      const element = document.getElementById("dynamicElement");
      if (element) {
        element.classList.add("newClass");
      }
    });

    // Add event listener to initialize the scheduling button once the script is loaded
    script.addEventListener("load", initializeSchedulingButton);
  }
  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`} id={id}>
      <ContentWithPaddingXl>
        <PrimaryBackgroundContainer>
          <Row>
            <TextContainer>
              <Header>{header}</Header>
              <Text>{text}</Text>
            </TextContainer>
            <LinksContainer>
              {primaryLinkUrl && (
                <PrimaryLink
                  href={primaryLinkUrl}
                  onClick={(e) => handleScroll(e, "#newsletter")}
                >
                  {primaryLinkText}
                </PrimaryLink>
              )}
              {secondaryLinkUrl && !googleSchedule && (
                <SecondaryLink
                  href={secondaryLinkUrl}
                  onClick={(e) => handleScroll(e, "#book-a-demo")}
                >
                  {buttonLabel}
                </SecondaryLink>
              )}
              {googleSchedule && <div id="google-schedule-button"></div>}
            </LinksContainer>
          </Row>
          {withBlobs && (
            <DecoratorBlobContainer>
              <DecoratorBlob1 />
              <DecoratorBlob2 />
            </DecoratorBlobContainer>
          )}
        </PrimaryBackgroundContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};

import React from "react";
import { createRoot } from "react-dom/client";
// import App from "./App";
import Modal from "react-modal";
import "./styles/variables.css";
import EstatiaLandingPage from "../src/demos/EstatiaLandingPage";

Modal.setAppElement("#root");

const container = document.getElementById("root");
const root = createRoot(container);
// root.render(<App />);
root.render(<EstatiaLandingPage />);
